<template>
  <i-modal
    :title="
      type == 'customer'
        ? $tc('history_customer', 2)
        : $tc('history_supplier', 2)
    "
    :value="value"
    :load="load"
    :max-width="'1200px'"
    dontSave
    @input="$emit('input', $event)"
  >
    <v-card-text>
      <v-tabs
        color="primary"
        v-model="mode"
        class="mb-2"
        next-icon="fa-chevron-right"
        prev-icon="fa-chevron-left"
        centered
        show-arrows
        :icons-and-text="$vuetify.breakpoint.smAndUp"
      >
        <v-tab
          v-if="
            type === 'customer' &&
            (getPermissions([`invoices.view_invoice`]) || isAdmin)
          "
          href="#invoice"
        >
          <span
            class="mt-2"
            v-if="$vuetify.breakpoint.smAndUp"
            v-text="$t('invoices')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp" dark
            >fas fa-file-invoice</v-icon
          >
        </v-tab>
        <v-tab
          v-if="getPermissions([`product.view_product`]) || isAdmin"
          href="#product"
        >
          <span
            class="mt-2"
            v-text="$t('history_product')"
            v-if="$vuetify.breakpoint.smAndUp"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp" dark
            >fa-shopping-cart</v-icon
          >
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="mode" class="py-5">
        <v-tab-item value="invoice">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              lg="4"
              v-for="x in [1, 2, 3, 4]"
              v-show="load"
              :key="'skeleton-' + x"
            >
              <v-skeleton-loader
                type="article"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-col>
            <v-col
              align="center"
              justify="center"
              class="display-1 font-weight-black grey--text"
              cols="12"
              v-show="invoice.length == 0"
            >
              {{ $vuetify.lang.t('$vuetify.noDataText') }}
            </v-col>
            <v-col cols="12" v-if="invoice.length > 0">
              <v-row no-gutters class="pl-4 font-weight-black">
                <v-col cols="6" md="2" lg="2">Nº</v-col>
                <v-col cols="6" md="2" lg="2">
                  {{ $t('date') }}
                </v-col>
                <v-col cols="6" md="2" lg="2">
                  {{ $t('total') }}
                </v-col>
                <v-col cols="6" md="2" lg="2">
                  {{ $tc('paid', 1) }}
                </v-col>
                <v-col cols="6" md="2" lg="2">
                  {{ $t('balance') }}
                </v-col>
                <v-col cols="6" md="2" lg="2">
                  {{ $t('status') }}
                </v-col>
              </v-row>
              <v-list>
                <v-list-item v-for="(item, i) in invoice" :key="`invoice-${i}`">
                  <v-row no-gutters>
                    <v-col cols="6" md="2" lg="2">
                      {{ item.invoice_number }}
                    </v-col>
                    <v-col cols="6" md="2" lg="2">
                      {{ dateFormat(item.date) }}
                    </v-col>
                    <v-col cols="6" md="2" lg="2">
                      <i-money
                        mode="text"
                        :value="item.total === null ? 0 : item.total"
                      />
                    </v-col>
                    <v-col cols="6" md="2" lg="2">
                      <i-money
                        mode="text"
                        :value="
                          item.total - item.balance === item.paid
                            ? item.paid
                            : item.total - item.balance
                        "
                      />
                    </v-col>
                    <v-col cols="6" md="2" lg="2">
                      <i-money
                        mode="text"
                        :value="item.balance === null ? 0 : item.balance"
                      />
                    </v-col>
                    <v-col cols="6" md="2" lg="2">
                      {{ $t(item.status) }}
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="product">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              lg="4"
              v-for="x in [1, 2, 3, 4]"
              v-show="load"
              :key="'skeleton-' + x"
            >
              <v-skeleton-loader
                type="article"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-col>
            <v-col
              align="center"
              justify="center"
              class="display-1 font-weight-black grey--text"
              cols="12"
              v-show="product.length == 0"
            >
              {{ $vuetify.lang.t('$vuetify.noDataText') }}
            </v-col>
            <v-col cols="12" v-if="product.length > 0">
              <v-row no-gutters class="pl-4 font-weight-black">
                <v-col cols="6" md="3" lg="2" v-if="type === 'customer'">
                  {{ $tc('suborder', 1) }}
                </v-col>
                <v-col cols="6" md="3" lg="2">
                  {{ $t('product_code') }}
                </v-col>
                <v-col cols="6" md="3" lg="2">
                  {{ $t('description') }}
                </v-col>
                <v-col cols="6" md="3" lg="2">
                  {{ $tc('quantity', 1) }}
                </v-col>
                <v-col cols="6" md="3" lg="2" v-if="type === 'customer'">
                  {{ $tc('price', 1) }}
                </v-col>
                <v-col cols="6" md="3" lg="2" v-if="type === 'customer'">
                  {{ $tc('total', 1) }}
                </v-col>
              </v-row>
              <v-list>
                <v-list-item v-for="(item, i) in product" :key="`invoice-${i}`">
                  <v-row no-gutters>
                    <v-col cols="6" md="3" lg="2" v-if="type === 'customer'">
                      {{ item.name }}
                    </v-col>
                    <v-col cols="6" md="3" lg="2">
                      {{ item.product__internalCode }}
                    </v-col>
                    <v-col cols="6" md="3" lg="2">
                      {{ item.product__name }}
                    </v-col>
                    <v-col cols="6" md="3" lg="2" v-if="type === 'supplier'">
                      {{ item.shop }}
                    </v-col>
                    <v-col cols="6" md="3" lg="2" v-if="type === 'customer'">
                      {{
                        item.price === 0
                          ? '0.00'
                          : Number(item.total / item.price).toFixed(2)
                      }}
                    </v-col>
                    <v-col cols="6" md="3" lg="2" v-if="type === 'customer'">
                      {{ Number(item.price).toFixed(2) }}
                    </v-col>
                    <v-col cols="6" md="3" lg="2" v-if="type === 'customer'">
                      {{ Number(item.total).toFixed(2) }}
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </i-modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      load: false,
      invoice: [],
      product: [],
      pk: null,
      mode: 'invoice'
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    type: {
      type: String
    }
  },
  watch: {
    mode: {
      handler() {
        this.getValues()
      }
    },
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.pk = this.toEdit.pk
          this.getValues()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    async getValues() {
      this.load = true
      try {
        let response =
          this.type === 'customer'
            ? await this.$api.customer.history({
                pk: this.pk,
                opt: { params: { mode: this.mode } }
              })
            : await this.$api.supplier.history({
                pk: this.pk,
                opt: { params: { mode: this.mode } }
              })
        this[this.mode] = response.data
      } finally {
        this.load = false
      }
    }
  }
}
</script>
